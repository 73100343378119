import React from "react";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import PageMeta from "../../components/common/PageMeta";
import FooterOne from "../../layout/Footer/FooterOne";
import HeroTwo from "./HeroTwo";
import ReviewOne from "../../components/review/ReviewOne";
import FeatureTwo from "../../components/features/FeatureTwo";
import WorkProcessTwo from "../../components/work-process/WorkProcessTwo";
import IntegrationOne from "../../components/integration/IntegrationOne";
import CtaTwo from "../../components/cta/CtaTwo";
import TestimonialOne from "../../components/testimonial/TestimonialOne";
import LatestBlog from "../../components/blog/LatestBlog";
import FeatureImgContentFour from "../../components/feature-img-content/FeatureImgContentFour";
import SectionTitle from "../../components/common/SectionTitle";
import PriceOne from "../../components/prices/PriceOne";

const HomeSassTwo = () => {
  return (
    <Layout>
      <PageMeta title="Lisa - Software &amp; for your business" />
      <Navbar />
      <HeroTwo />
      <ReviewOne />
      <FeatureTwo />
      <WorkProcessTwo />
      <IntegrationOne />
      <div style={{ width: "60%", margin: "auto" }}>
        <SectionTitle
          subtitle=""
          title="App Features"
          description="Get to Lisa is designed for various types of businesses, including shop owners, service providers, and catering businesses. It caters to the needs of small and medium-sized enterprises (SMEs) across different industries, helping them manage their operations effectively and make data-driven decisions."
          centerAlign
        />
      </div>

      <FeatureImgContentFour />
      <TestimonialOne hasSubtitle />
      {/* <LatestBlog /> */}
      <PriceOne paddingTop="pt-60 pb-120" haspriceTitleWithHeader />

      {/* <CtaTwo /> */}
      <FooterOne footerLight />
    </Layout>
  );
};

export default HomeSassTwo;
