import React from 'react';
import { Link } from 'react-router-dom';

const Team = () => {
  return (
    <>
      <section id="our-team" className="team-section pt-60 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
              <div className="section-heading text-center">
                <h5 className="h6 text-primary">Our Team</h5>
                <h2>The People Behind Lisa</h2>
                <p>
                  Meet the passionate individuals who drive the innovation and
                  success of Phindor.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="team-single-wrap mb-5">
                <div className="team-img rounded-custom">
                  <img
                    src="assets/img/team/team-1.jpg"
                    alt="team"
                    className="img-fluid position-relative"
                  />
                  <ul className="list-unstyled team-social-list d-flex flex-column mb-0">
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-github"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="team-info mt-4 text-center">
                  <h5 className="h6 mb-1">Pheneas Munene</h5>
                  <p className="text-muted small mb-0">CEO, Phindor</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="team-single-wrap mb-5">
                <div className="team-img rounded-custom">
                  <img
                    src="assets/img/team/team-2.jpg"
                    alt="team"
                    className="img-fluid position-relative"
                  />
                  <ul className="list-unstyled team-social-list d-flex flex-column mb-0">
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-github"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="team-info mt-4 text-center">
                  <h5 className="h6 mb-1">John Maina</h5>
                  <p className="text-muted small mb-0">Marketing Lead</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="team-single-wrap mb-5">
                <div className="team-img rounded-custom">
                  <img
                    src="assets/img/team/team-3.jpg"
                    alt="team"
                    className="img-fluid position-relative"
                  />
                  <ul className="list-unstyled team-social-list d-flex flex-column mb-0">
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-github"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="team-info mt-4 text-center">
                  <h5 className="h6 mb-1">Annie Axelle</h5>
                  <p className="text-muted small mb-0">Customer Service Lead</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="team-single-wrap mb-5">
                <div className="team-img rounded-custom">
                  <img
                    src="assets/img/team/team-4.jpg"
                    alt="team"
                    className="img-fluid position-relative"
                  />
                  <ul className="list-unstyled team-social-list d-flex flex-column mb-0">
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-github"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="team-info mt-4 text-center">
                  <h5 className="h6 mb-1">Jairus Otiko</h5>
                  <p className="text-muted small mb-0">
                    Lead Software Engineer
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
