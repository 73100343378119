import React from 'react';
import { Link } from 'react-router-dom';
import customer from '../../customer1.png'
import customer2 from "../../customer2.png";
import customer3 from "../../woman.png";
const ReviewOne = () => {
  return (
    <>
      <section className="customer-review pb-120 bg-dark">
        <div className="container">
          <div className="row">
            <div className="section-heading text-center" data-aos="fade-up">
              <h2 className="fw-medium h4">
                Rated 5 out of 5 stars by our customers
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div
                className="
                  review-info-wrap
                  text-center
                  rounded-custom
                  d-flex
                  flex-column
                  h-100
                  p-lg-5
                  p-4
                "
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <img
                  src={customer2}
                  width="130"
                  alt="facebook"
                  className="img-fluid m-auto round"
                  style={{ borderRadius: 130 }}
                />
                <div className="review-info-content mt-2">
                  <p className="mb-4">
                    "Lisa has an intuitive marketing platform that allowed me send targeted markerting campains to my customers, such a great tool "
                  </p>
                </div>
                <Link
                  to="/about-us"
                  className="
                    link-with-icon
                    p-0
                    mt-auto
                    text-decoration-none text-warning
                  "
                >
                  ~ James Omolo
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div
                className="
                  review-info-wrap
                  text-center
                  rounded-custom
                  d-flex
                  flex-column
                  h-100
                  p-lg-5
                  p-4
                "
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <img
                  src={customer}
                  width="130"
                  alt="facebook"
                  className="img-fluid m-auto round"
                  style={{ borderRadius: 130 }}
                />
                <div className="review-info-content mt-2">
                  <p className="mb-4">
                   " The Lisa Open API has been a go to tool for cloud data pipeline needs "
                  </p>
                </div>
                <Link
                  to="/about-us"
                  className="
                    link-with-icon
                    p-0
                    mt-auto
                    text-decoration-none text-warning
                  "
                >
                  ~ Amos Njuguna
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div
                className="
                  review-info-wrap
                  text-center
                  rounded-custom
                  d-flex
                  flex-column
                  h-100
                  p-lg-5
                  p-4
                "
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <img
                  src={customer3}
                  width="130"
                  alt="facebook"
                  className="img-fluid m-auto round"
                  style={{ borderRadius: 130 }}
                />
                <div className="review-info-content mt-2">
                  <p className="mb-4">
                  " Lisa has help me make data driven business decisions that have helped me tremendously in the long run "
                  </p>
                </div>
                <Link
                  to="/about-us"
                  className="
                    link-with-icon
                    p-0
                    mt-auto
                    text-decoration-none text-warning
                  "
                >
                 ~ Irene Kemunto
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReviewOne;
