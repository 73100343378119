import React, { useState } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../common/SectionTitle";
import { Input, Modal } from "antd";
import axios from "axios";

const PriceOne = ({ paddingTop, haspriceTitleWithHeader }) => {
  const [phone, setPhone] = useState("");
  const [loading, setloading] = useState(false);
  const [modal, setModal] = useState(false);
  return (
    <>
      <section
        className={`pricing-section ${paddingTop} position-relative z-2`}
      >
        <div className="container">
          {haspriceTitleWithHeader ? (
            <div>
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-10">
                  <SectionTitle
                    subtitle="Pricing"
                    variant="text-primary"
                    title="Check Our Valuable Price"
                    description="Conveniently mesh cooperative services via magnetic
                  outsourcing. Dynamically grow value whereas accurate
                  e-commerce vectors."
                    centerAlign
                  />
                </div>
              </div>
              <div className="row justify-content-center mb-5">
                <div className="col-lg-3">
                  <div className="media d-flex align-items-center py-2 p-sm-2">
                    <div
                      className="
                    icon-box
                    mb-0
                    bg-primary-soft
                    rounded-circle
                    d-block
                    me-3
                  "
                    >
                      <i className="fal fa-credit-card text-primary"></i>
                    </div>
                    <div className="media-body fw-medium h6 mb-0">
                      No credit card required
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="media d-flex align-items-center py-2 p-sm-2">
                    <div
                      className="
                    icon-box
                    mb-0
                    bg-success-soft
                    rounded-circle
                    d-block
                    me-3
                  "
                    >
                      <i className="fal fa-calendar-check text-success"></i>
                    </div>
                    <div className="media-body fw-medium h6 mb-0">
                      Get 30 day free trial
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="media d-flex align-items-center py-2 p-sm-2">
                    <div
                      className="
                    icon-box
                    mb-0
                    bg-danger-soft
                    rounded-circle
                    d-block
                    me-3
                  "
                    >
                      <i className="fal fa-calendar-times text-danger"></i>
                    </div>
                    <div className="media-body fw-medium h6 mb-0">
                      Cancel anytime
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <Modal footer={null} open={modal}>
              <div style={{ marginTop: 20 }}>
                <p>Enter Phone Number</p>
                <Input
                  placeholder="254712345678"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
                {loading ? (
                  <div className="btn btn-primary mt-2">Processing...</div>
                ) : (
                  <a
                    onClick={() => {
                      setloading(true);
                      axios
                        .post(
                          "https://phindor.africa/live-server/phindor-api/public/api/make/mpesa/payment",
                          {
                            amount: 2000,
                            business_id: 641,
                            phone: phone,
                            premium_until: 24,
                            user_id: 127,
                          }
                        )
                        .then((res) => {
                          setloading(false);
                          setModal(false);
                        });
                    }}
                    className="btn btn-primary mt-2"
                  >
                    Complete Purchase
                  </a>
                )}
              </div>
            </Modal>

            <div className="col-lg-4 col-md-6 mx-auto ">
              <div
                className="
                  position-relative
                  single-pricing-wrap
                  rounded-custom
                  bg-gradient
                  text-white
                  p-5
                  mb-4 mb-lg-0
                "
              >
                <div className="pricing-header mb-32">
                  <h3 className="package-name text-warning d-block">
                    Advanced
                  </h3>
                  <h4 className="display-6 fw-semi-bold">
                    KES 4500<span>/month</span>
                  </h4>
                </div>
                <div className="pricing-info mb-4">
                  <ul className="pricing-feature-list list-unstyled">
                    <li>
                      <i className="fas fa-circle fa-2xs text-warning me-2"></i>{" "}
                      5 Team
                    </li>
                    <li>
                      <i className="fas fa-circle fa-2xs text-warning me-2"></i>{" "}
                      3 Installed Agent
                    </li>
                    <li>
                      <i className="fas fa-circle fa-2xs text-warning me-2"></i>
                      Real-Time Feedback
                    </li>
                    <li>
                      <i className="fas fa-circle fa-2xs text-warning me-2"></i>
                      Video Dedicated Support
                    </li>
                    <li>
                      <i className="fas fa-circle fa-2xs text-warning me-2"></i>{" "}
                      24 Attacked Targets Per Month
                    </li>
                    <li>
                      <i className="fas fa-circle fa-2xs text-warning me-2"></i>
                      Team Collaboration Tools
                    </li>
                    <li>
                      <i className="fas fa-circle fa-2xs text-warning me-2"></i>
                      Automated Updated Features
                    </li>
                    <li>
                      <i className="fas fa-circle fa-2xs text-warning me-2"></i>
                      24/7 Life time Support
                    </li>
                  </ul>
                </div>
                <div
                  onClick={() => setModal(true)}
                  className="btn btn-primary mt-2"
                >
                  Buy Now
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PriceOne;
