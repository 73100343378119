import React from 'react';
import { Link } from 'react-router-dom';

const IntegrationOne = () => {
  return (
    <>
      <section className="integration-section ptb-120">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-3">
              <div className="integration-list-wrap">
                <a
                  href="integration-single.html"
                  className="integration-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Your Brand Name"
                >
                  <img
                    src="assets/img/integations/1.png"
                    alt="integration"
                    className="img-fluid rounded-circle"
                  />
                </a>
                <a
                  href="integration-single.html"
                  className="integration-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Your Brand Name"
                >
                  <img
                    src="assets/img/integations/2.png"
                    alt="integration"
                    className="img-fluid rounded-circle"
                  />
                </a>
                <a
                  href="integration-single.html"
                  className="integration-3"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Your Brand Name"
                >
                  <img
                    src="assets/img/integations/3.png"
                    alt="integration"
                    className="img-fluid rounded-circle"
                  />
                </a>

                <a
                  href="integration-single.html"
                  className="integration-4"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Your Brand Name"
                >
                  <img
                    src="assets/img/integations/4.png"
                    alt="integration"
                    className="img-fluid rounded-circle"
                  />
                </a>
                <a
                  href="integration-single.html"
                  className="integration-5"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Your Brand Name"
                >
                  <img
                    src="assets/img/integations/5.png"
                    alt="integration"
                    className="img-fluid rounded-circle"
                  />
                </a>
                <a
                  href="integration-single.html"
                  className="integration-6"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Your Brand Name"
                >
                  <img
                    src="assets/img/integations/6.png"
                    alt="integration"
                    className="img-fluid rounded-circle"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="section-heading text-center my-5 my-lg-0 my-xl-0">
                <h4 className="text-primary h5">Seemless Integration</h4>
                <h2>Lisa supported integrations</h2>
                <Link to="/integrations" className="mt-4 btn btn-primary">
                  View all Integration
                </Link>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="col-lg-4">
                <div className="integration-list-wrap">
                  <a
                    href="integration-single.html"
                    className="integration-7"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Your Brand Name"
                  >
                    <img
                      src="assets/img/integations/7.png"
                      alt="integration"
                      className="img-fluid rounded-circle"
                    />
                  </a>
                  <a
                    href="integration-single.html"
                    className="integration-8"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Your Brand Name"
                  >
                    <img
                      src="assets/img/integations/8.png"
                      alt="integration"
                      className="img-fluid rounded-circle"
                    />
                  </a>
                  <a
                    href="integration-single.html"
                    className="integration-9"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Your Brand Name"
                  >
                    <img
                      src="assets/img/integations/9.png"
                      alt="integration"
                      className="img-fluid rounded-circle"
                    />
                  </a>

                  <a
                    href="integration-single.html"
                    className="integration-10"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Your Brand Name"
                  >
                    <img
                      src="assets/img/integations/10.png"
                      alt="integration"
                      className="img-fluid rounded-circle"
                    />
                  </a>
                  <a
                    href="integration-single.html"
                    className="integration-11"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Your Brand Name"
                  >
                    <img
                      src="assets/img/integations/11.png"
                      alt="integration"
                      className="img-fluid rounded-circle"
                    />
                  </a>
                  <a
                    href="integration-single.html"
                    className="integration-12"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Your Brand Name"
                  >
                    <img
                      src="assets/img/integations/12.png"
                      alt="integration"
                      className="img-fluid rounded-circle"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-100">
            <div className="col-lg-5 col-md-12">
              <a
                href="integration-single.html"
                className="mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-2 rounded-custom d-block overflow-hidden p-5"
              >
                <div className="position-relative connected-app-content">
                  <div className="integration-logo bg-custom-light rounded-circle p-2 d-inline-block">
                    <img
                      src="assets/img/integations/9.png"
                      width="40"
                      alt="integration"
                      className="img-fluid"
                    />
                  </div>
                  <h5>Slack </h5>
                  <p className="mb-0 text-body">
                    Lisa is able to send slack notifications directly into your
                    companies slack channel.
                  </p>
                </div>
                <span className="position-absolute integration-badge badge px-3 py-2 bg-primary-soft text-primary">
                  Connect
                </span>
              </a>
            </div>

            <div className="col-lg-5 col-md-12">
              <a
                href="integration-single.html"
                className="position-relative text-decoration-none connected-app-single border border-light border-2 rounded-custom d-block overflow-hidden p-5"
              >
                <div className="position-relative connected-app-content">
                  <div className="integration-logo bg-custom-light rounded-circle p-2 d-inline-block">
                    <img
                      src="assets/whatsapp.jpeg"
                      width="40"
                      alt="integration"
                      className="img-fluid"
                    />
                  </div>
                  <h5>WhatsApp</h5>
                  <p className="mb-0 text-body">
                    Engage your customers via whatsapp to inform them when new
                    stock arrives.
                  </p>
                </div>
                <span className="position-absolute integration-badge badge px-3 py-2 bg-danger-soft text-danger">
                  Connected
                </span>
              </a>
            </div>
          </div>
          <div className="row justify-content-center mt-100">
            <div className="col-lg-5 col-md-12">
              <a
                href="integration-single.html"
                className="mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-2 rounded-custom d-block overflow-hidden p-5"
              >
                <div className="position-relative connected-app-content">
                  <div className="integration-logo bg-custom-light rounded-circle p-2 d-inline-block">
                    <img
                      src="assets/img/integations/9.png"
                      width="40"
                      alt="integration"
                      className="img-fluid"
                    />
                  </div>
                  <h5>Email </h5>
                  <p className="mb-0 text-body">
                    Lisa is able to send slack notifications directly into your
                    companies slack channel.
                  </p>
                </div>
                <span className="position-absolute integration-badge badge px-3 py-2 bg-primary-soft text-primary">
                  Connect
                </span>
              </a>
            </div>

            <div className="col-lg-5 col-md-12">
              <a
                href="integration-single.html"
                className="position-relative text-decoration-none connected-app-single border border-light border-2 rounded-custom d-block overflow-hidden p-5"
              >
                <div className="position-relative connected-app-content">
                  <div className="integration-logo bg-custom-light rounded-circle p-2 d-inline-block">
                    <img
                      src="assets/whatsapp.jpeg"
                      width="40"
                      alt="integration"
                      className="img-fluid"
                    />
                  </div>
                  <h5>Bulk SMS</h5>
                  <p className="mb-0 text-body">
                    Engage your customers via whatsapp to inform them when new
                    stock arrives.
                  </p>
                </div>
                <span className="position-absolute integration-badge badge px-3 py-2 bg-danger-soft text-danger">
                  Connected
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrationOne;
