import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OffCanvasMenu from "./OffCanvasMenu";

const Navbar = ({ navDark }) => {
  const [scrollingUp, setScrollingUp] = useState(false);
  const [headerTop, setHeaderTop] = useState(0);
  const [controll, setControl] = useState(0);

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [navbarVisible, setNavbarVisible] = useState(true);

  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    setScrollingUp(true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollTop = window.scrollY;

    setLastScrollTop((prevScrollTop) => {
      if (currentScrollTop > prevScrollTop) {
        console.log("Scrolling down");
        setScrollingUp(false);
      } else {
        console.log("Scrolling up");
        setScrollingUp(true);
      }

      return currentScrollTop;
    });
  };

  return (
    <>
      <header
        style={{ marginBottom: 30 }}
        className={`main-header ${navDark ? "position-absolute " : ""} w-100 `}
      >
        <nav
          style={{
            display: scrollingUp ? "block" : "none",
          }}
          className={`navbar navbar-expand-xl z-10 ${
            navDark ? "navbar-dark" : "navbar-light"
          } sticky-header ${scrollingUp ? "affix" : ""}`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img
                style={{ height: 30 }}
                src="assets/img/logo-white.png"
                alt="logo"
                className="img-fluid logo-white"
              />
              <img
                style={{ height: 30 }}
                src="assets/img/logo-color.png"
                alt="logo"
                className="img-fluid logo-color"
              />
            </Link>
            <Link
              className="navbar-toggler position-absolute right-0 border-0"
              to="#offcanvasWithBackdrop"
              role="button"
            >
              <span
                className="far fa-bars"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
              ></span>
            </Link>

            <div className="collapse navbar-collapse justify-content-center">
              <ul className="nav col-12 col-md-auto  main-menu">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link "
                    to="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Home
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link to="/about-us" className="nav-link">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" className="nav-link">
                    Resources
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" className="nav-link">
                    Get Started
                  </Link>
                </li>
              </ul>
            </div>

            <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
              {/* <a
                href="https://lisa.phindor.com/authentication/login/"
                className="btn btn-link text-decoration-none me-2"
              >
                Sign In
              </a> */}
              <a href="https://lisa.phindor.com/" className="btn btn-primary">
                Get Started
              </a>
            </div>
            <OffCanvasMenu />
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
