import React from "react";

const WorkProcessTwo = () => {
  return (
    <>
      <section className="work-process ptb-120 bg-dark text-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-6">
              <div className="section-heading text-center" data-aos="fade-up" style={{position:"relative"}}>
                {/* <div style={{width:200,  height:200, background:"red", position:"absolute", top:10,}}>szxsds</div> */}

                <h2>How It Works</h2>
                <p>
                  In simple terms, Lisa works by collecting and analyzing data
                  from your business operations. It then uses this data to
                  provide insights and predictions, helping you make informed
                  decisions to improve your business. Whether you're a shop
                  owner, service provider, or run a catering business, Lisa's
                  features and AI capabilities are tailored to suit your
                  specific needs.
                </p>
              </div>
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-md-6 col-lg-3">
              <div
                className="
                  process-card-two
                  text-center
                  px-4
                  py-lg-5
                  py-4
                  rounded-custom
                "
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div
                  className="
                    process-icon
                    border border-light
                    bg-custom-light
                    rounded-custom
                    p-3
                  "
                >
                  <i className="fad fa-folder-tree fa-2x"></i>
                </div>
                <h3 className="h5">collecting data </h3>
                <p className="mb-0">
                  Lisa is collects data though a tone of integration options
                  provided, API, web app or mobile app.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div
                className="
                  process-card-two
                  text-center
                  px-4
                  py-lg-5
                  py-4
                  rounded-custom
                "
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div
                  className="
                    process-icon
                    border border-light
                    bg-custom-light
                    rounded-custom
                    p-3
                  "
                >
                  <i className="fad fa-bezier-curve fa-2x"></i>
                </div>
                <h3 className="h5">Data Analysis</h3>
                <p className="mb-0">
                  Lisa efficiently analyses the collected data through the use
                  of Machine learning and AI
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div
                className="
                  process-card-two
                  text-center
                  px-4
                  py-lg-5
                  py-4
                  rounded-custom
                "
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div
                  className="
                    process-icon
                    border border-light
                    bg-custom-light
                    rounded-custom
                    p-3
                  "
                >
                  <i className="fad fa-layer-group fa-2x"></i>
                </div>
                <h3 className="h5">Predicting</h3>
                <p className="mb-0">
                  Credibly maintain a good predicting accuracy score to allow
                  make informed decisions.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div
                className="
                  process-card-two
                  text-center
                  px-4
                  py-lg-5
                  py-4
                  rounded-custom
                "
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div
                  className="
                    process-icon
                    border border-light
                    bg-custom-light
                    rounded-custom
                    p-3
                  "
                >
                  <i className="fad fa-truck fa-2x"></i>
                </div>
                <h3 className="h5">Continuous Learning</h3>
                <p className="mb-0">
                  Lisa seemlessly uses webhooks to automatically receive data
                  streams.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcessTwo;
