import React from 'react';
import { Link } from 'react-router-dom';

const AboutPageHero = () => {
  return (
    <>
      <section
        className="about-header-section ptb-120 position-relative overflow-hidden bg-dark"
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat center right",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading-wrap d-flex justify-content-between z-5 position-relative">
                <div className="about-content-left">
                  <h1 className="fw-bold display-5">About Us</h1>

                  <div className="about-info mb-5">
                    <p className="lead">
                      Welcome to Phindor, where innovation meets insight.
                    </p>
                    {/* <h1 className="fw-bold display-5"> Our Story</h1> */}
                    <p className="lead">
                      Our mission is clear: to empower businesses of all sizes
                      with intelligent insights derived from advanced data
                      analytics and artificial intelligence. We understand that
                      in today's fast-paced and dynamic business environment,
                      staying ahead requires more than just information – it
                      demands foresight.
                    </p>
                    <Link to="#" className="btn btn-primary mt-4 me-3">
                      Open Positions
                    </Link>
                    <Link to="/about-us" className="btn btn-soft-primary mt-4">
                      Meet Our Team
                    </Link>
                  </div>
                </div>
                <img
                  src="assets/img/about-img-1.jpg"
                  alt="about"
                  className="img-fluid about-img-first mt-5 rounded-custom shadow"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z-2 py-5"></div>
      </section>
    </>
  );
};

export default AboutPageHero;
