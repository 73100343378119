import React from "react";
import { Link } from "react-router-dom";
import HeroTitle from "../../components/common/HeroTitle";
import TestimonialModal from "../../components/testimonial/TestimonialModal";

const HeroTwo = () => {
  return (
    <>
      <section
        className="hero-section ptb-120 position-relative overflow-hidden"
        style={{
          background:
            "url('assets/img/shape/color-particles-2.svg') no-repeat center top",
        }}
      >
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-xl-8 col-lg-10 mb-5">
              <div className="hero-content-wrap">
                <HeroTitle
                  title="  Elevate Your Business with Lisa!"
                  desc="    Welcome to Lisa, your comprehensive business assistant designed to transform your operations. Lisa offers a suite of powerful features to optimize your business, track financials, streamline tasks, and supercharge your marketing efforts—all with the help of cutting-edge AI technology."
                />
                <div
                  className="action-btns text-center pt-4"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <a
                    href="https://developer.phindor.com/"
                    className="btn btn-primary me-3"
                  >
                    Developers
                  </a>
                  <a
                    href="https://lisa.phindor.com/"
                    className="btn btn-outline-primary"
                  >
                    Get App
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <ul
                  className="
              position-absolute
              animate-element
              parallax-element
              widget-img-wrap
              z-2
            "
                >
                  <li className="layer" data-depth="0.04">
                    {/* <img
                      src="assets/img/screen/widget-3.png"
                      alt="widget-img"
                      className="
                  img-fluid
                  widget-img-1
                  position-absolute
                  shadow-lg
                  rounded-custom
                "
                    /> */}
                  </li>
                  <li className="layer" data-depth="0.02">
                    {/* <img
                      src="assets/img/screen/widget-4.png"
                      alt="widget-img"
                      className="
                  img-fluid
                  widget-img-3
                  position-absolute
                  shadow-lg
                  rounded-custom
                "
                    /> */}
                  </li>
                </ul>
                <div className="">
                  <div className=" pt-5 ps-5">
                    <div className="testimonial-video-wrapper col-12  position-relative">
                      <img
                        src="assets/img/testimonial/t-1.jpg"
                        className="img-fluid rounded-custom shadow-lg"
                        alt="testimonial author"
                      />
                      <TestimonialModal />
                      <div className="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="
      bg-dark
      position-absolute
      bottom-0
      h-25
      bottom-0
      left-0
      right-0
      z--1
      py-5
    "
        ></div>
      </section>
    </>
  );
};

export default HeroTwo;
